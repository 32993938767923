@media (min-width: 992px){
    .card{
        form{
            [class*="col-"]{
                padding: 6px;
            }
            [class*="col-"]:first-child{
                padding-left: 15px;
            }
            [class*="col-"]:last-child{
                padding-right: 15px;
            }
        }
    }
}

/*          Changes for small display      */

@media (max-width: 991px){
    .main-panel{
        width: 100%;
    }
    .main-panel{
       @include transform-translate-x(0px);
       @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
       left: 0;
    }

    .navbar-nav > li{
        float: none;
        position: relative;
        display: block;
    }

    .sidebar {
        display: block;
        @include transform-translate-x(-260px);
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    }

    .navbar-toggle .icon-bar {
        display: block;
        position: relative;
        background: #fff;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        margin: 0 auto;
    }

    .form-control + .form-control-feedback{
        margin-top: -8px;
    }

    .navbar-collapse{
        [class^="pe-7s-"]{
            float: left;
            font-size: 20px;
            margin-right: 10px;
        }
    }
}

//overwrite table responsive for 768px screens

@media (min-width: 992px){
    .table-full-width{
        margin-left: -15px;
        margin-right: -15px;
    }
    .table-responsive{
        overflow: visible;
    }

}

@media (max-width: 991px){
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }

}