.table{
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       padding: 12px 8px;
       vertical-align: middle;
   }
   > tbody > tr > td{
       font-size: 14px;
   }
    > thead > tr > th{
        border-bottom-width: 1px;
        font-size: $font-size-small;
        text-transform: uppercase;
        color: $dark-gray;
        font-weight: $font-weight-normal;
        padding-bottom: 5px;
    }
   > tbody > tr > td {
     a {
       margin: 0 2px;
     }
   }

    > tbody > tr{
        position: relative;

        &:hover{
            .td-actions .btn{
                @include opacity(1);
            }
        }
    }
}