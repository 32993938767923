.navbar {
    border: $none;
    font-size: $font-size-navbar;
    border-radius: 0;
    padding: 0;

    .navbar-brand {
        font-weight: $font-weight-normal;
        margin: $navbar-margin-brand;
        padding: $navbar-padding-brand;
        font-size: $font-size-large-navbar;
    }

    .navbar-nav{
        > .nav-link {
            margin-left: 15px;
            line-height: 30px;
            color: #939393;
        }
        > .active {
            color: #EF6981 !important;
            opacity: 1;
        }
    }
    .navbar-collapse {
        padding-left: 20px;
        padding-right: 20px;
        > hr {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
    
    &.fixed{
        width: calc(100% - #{$sidebar-width});
        right: 0;
        left: auto;
        border-radius: 0;
    }

}

.navbar-light {
    background-color: $white-navbar;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .navbar-brand {
        color: #777777;
    }

    .navbar-toggler {
        margin-right: 1rem;
    }

    .navbar-nav{
        a:not(.btn){
            color: $dark-gray;
        }

        > .active > a,
        > .active > a:not(.btn):hover,
        > .active > a:not(.btn):focus,
        a:not(.btn):hover,
        a:not(.btn):focus {
            background-color: transparent;
            border-radius: 3px;
            color: $info-color;
            @include opacity(1);
        }
        
        > .open > a, 
        > .open > a:hover,
        > .open > a:focus{
            background-color: transparent;
            color: $info-color;
        }

        .navbar-toggle:hover,.navbar-toggle:focus {
            background-color: transparent;
        }

    }
}