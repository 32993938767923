.card{
    border-radius: $border-radius-base;
    box-shadow: 0 1px 2px rgba(0,0,0,.05),0 0 0 1px rgba(63,63,68,.1);
    background-color: #FFFFFF;
    margin-bottom: 30px;
    border: 0;

    .logo-cumc {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        width: 250px;
        height: 250px;
    }

    h2 {
        margin: 0;
    }

    .image{
        width: 100%;
        overflow: hidden;
        height: 260px;
        border-radius: $border-radius-base $border-radius-base 0 0;
        position: relative;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;

        img {
            width: 100%;
        }
    }
    > .card-body {
        display: flex;
        flex-direction: column;
        .row {
            margin-top: auto;
            .btn {
                margin-right: 5px;
            }
        }
    }
    .btn-hover{
        @include opacity(0);
    }
    &:hover .btn-hover{
        @include opacity(1);
    }
    .content{
        padding: 15px 15px 10px 15px;
    }
    .header{
        padding: 15px 15px 0;
    }
    .card-subtitle,
    label{
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        color: $dark-gray;
        text-transform: none;

        i{
            font-size: $font-paragraph;
        }
    }

    label{
        font-size: $font-size-small;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    .card-title{
        margin-bottom: 10px;
        color: $black-color;
        font-size: $font-size-h4;
        font-weight: $font-weight-light;
    }
    .avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 5px;
    }
    .description{
        font-size: $font-size-base;
        color: #333;
    }

    h6{
        font-size: $font-size-small;
        margin: 0;
    }

    .table{
        tbody td:first-child,
        thead th:first-child{
            padding-left: 15px;
        }

        tbody td:last-child,
        thead th:last-child{
            padding-right: 15px;
        }
    }
}
.card-user{
    .image{
        height: 110px;
    }
    .author{
        text-align: center;
        text-transform: none;
        margin-top: -70px;
    }
    .avatar{
        width: 124px;
        height: 124px;
        border: 5px solid #FFFFFF;
        position: relative;
        margin-bottom: 15px;

        &.border-gray{
            border-color: #EEEEEE;
        }
    }
    .title{
        line-height: 24px;
        margin-top: 15px;
    }
    > .card-body {
        padding-bottom: 0;
    }
}