.footer{    
    background-color: $white-color;
    line-height: $line-height;
    
    nav > ul{
        list-style: none;
        margin: 0;
        padding: 0;
        font-weight: normal;

        a:not(.btn){
            color: $dark-gray;
            display: block;
            margin-bottom: 3px;
            &:hover,
            &:focus{
                color: $default-states-color;
            }
        }
    }
}

.footer:not(.footer-big){
    nav > ul{
       font-size: $font-size-base;
       > li{
            margin-left: 20px;
            float: left !important;
        }
        a{
            padding: 10px 0;
            margin: 10px 10px 10px 0;
        }
    }
}